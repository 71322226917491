<template>
	<template v-if="items && items.length && (!onAward || items.length > 0)">
		<transition-group
			name="block-slide-in"
			:style="{ '--total': items.length }"
		>
			<template v-for="(item, i) in items">
				<ion-card
					:key="i"
					:style="{ '--i': i }"
					v-if="showList1 && showList2"
					@click="$router.push(item.url)"
				>
					<ion-card-header>
						<div class="percentage">{{ item.percentage.toFixed(0) }}%</div>
						<div class="title">
							<ion-card-title>{{ item.name }}</ion-card-title>
						</div>
						<div class="common-bar-container">
							<common-progress-bar
								:value="item.percentage / 100"
							></common-progress-bar>
						</div>
					</ion-card-header>
				</ion-card>
			</template>
		</transition-group>
	</template>
</template>

<script>
	import { useRoute } from "vue-router";
	import CampaignService from "../common/mixins/Campaign";
	import CommonProgressBar from "@/components/CommonProgressBar";

	import { IonCard, IonCardHeader, IonCardTitle } from "@ionic/vue";

	export default {
		name: "CampaignMenu",
		data() {
			return {
				items: null,
				showList1: false,
				showList2: false,
				campaignData: {},
			};
		},
		components: { CommonProgressBar, IonCard, IonCardHeader, IonCardTitle },
		mixins: [CampaignService],
		methods: {},
		props: ["onAward"],
		setup() {
			const route = useRoute();
			const { id } = route.params;
			return { id };
		},
		created() {},
		mounted() {
			const route = this.$route;
			const { id } = route.params;
			this.campaignId = id;

			this.showList1 = false;
			this.showList2 = false;
			// ApiService.get("CampaignManager/GetUserKpi/"+id, {
			this.getCampaign(id).then((data) => {
				console.log(data);
				this.campaignData = data;
				// this.items = res.data;

				this.items =
					this.campaignData.sezioniCampagna &&
					this.campaignData.sezioniCampagna.length
						? this.campaignData.sezioniCampagna
								.map((x) => {
									let name = "campaign_menu." + x.modulo.toLowerCase();
									return {
										name: this.$t(name),
										percentage: x.percCompletamento,
										url: this.getCampaignSectionRoute(this.campaignData, x),
									};
								})
								.filter((n) => n)
						: [];

				setTimeout(() => {
					this.$nextTick(() => {
						this.showList1 = true;
					});
				}, 1);
			});
			setTimeout(() => {
				this.$nextTick(() => {
					this.showList2 = true;
				});
			}, 1);
		},
	};
</script>

<style lang="scss" scoped>
	ion-card {
		//--background: var(--ion-color-primary-h);
		--background: var(--ion-color-primary);
		color: var(--text-color-light);

		ion-card-title {
			color: var(--text-color-light);
		}

		ion-progress-bar {
			margin-top: 4px;
			background: var(--ion-color-secondary);

			.progress,
			.progress-indeterminate {
				background: var(--ion-color-secondary) !important;
			}
		}

		ion-icon {
			font-size: 150%;
			margin: 0 5px -3px 0;
		}
	}

	.percentage {
		width: 100%;
		float: left;
		text-align: right;
		font-weight: bold;
		color: var(--text-color-light);
	}

	.title {
		display: block;
		clear: both;
	}

	ion-card {
		ion-button {
			margin-top: 15px;
		}
	}

	.common-bar-container {
		margin-top: 10px;
	}
</style>
